import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '../theme/mainTheme';
import { useParams } from 'react-router-dom';
import getPosts from '../api/getPosts';

import grafika from '../assets/img/grafika.jpg';

const StyledSinglePost = styled.section`
  padding-top: 0;
  .container-fluid {
    padding: 0;
  }
  .image-wrapper {
    height: calc(80vh - 15rem);
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .article-wrapper {
    background-color: ${theme.gray3};
    padding: 3.2rem 8rem 8rem;
    max-width: 96rem;
    margin: auto;
    h1 {
      color: white;

      font-size: calc(24px + (48 - 24) * ((100vw - 320px) / (1920 - 320)));
    }
    .date-wrapper {
      margin: 3.2rem 0;
      display: flex;
      p {
        color: white;
        font-size: 1.8rem;
        color: #b5b5b5;
      }
      P:first-of-type {
        margin-right: 3.2rem;
      }
    }
    .text-wrapper {
      p {
        font-size: 2rem;
        text-align: justify;
        margin-bottom: 2rem;
        color: #cccccc;
        &:first-of-type {
          color: ${theme.green};
        }
        a {
          color: inherit;
          text-decoration: underline;
        }
        img {
          margin-left: -8rem;
          margin-right: -8rem;
          width: calc(100% + 16rem);
          height: auto;
        }
        em {
          font-style: italic;
        }
      }
      ol,
      ul,
      li {
        font-size: 2rem;
        text-align: justify;
        margin-bottom: 2rem;
        color: #cccccc;
      }
      ol li {
        list-style: decimal;
        list-style-position: inside;
      }
    }
  }
`;

const Single = () => {
  let params = useParams();

  const [post, setPost] = useState(0);
  useEffect(() => {
    getPosts(`?slug=${params.slug}&_embed`).then((result) => setPost(result));
  }, []);

  return (
    <>
      {post && (
        <StyledSinglePost>
          <div className="container-fluid">
            <div className="row">
              <div className="col col-12">
                <div className="image-wrapper">
                  <img
                    src={post[0]._embedded['wp:featuredmedia'][0].link}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col col-12">
                <div className="article-wrapper">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: post[0].title.rendered,
                    }}
                  />
                  <div className="date-wrapper">
                    <p>Data: {post[0].date.slice(0, 10)}</p>
                    <p>Kategoria: Logbook</p>
                  </div>
                  <div
                    className="text-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: post[0].content.rendered,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </StyledSinglePost>
      )}
    </>
  );
};

export default Single;
