import React from 'react';

import { ThemeProvider } from 'styled-components';
import { theme } from '../theme/mainTheme';
import GlobalStyle from '../theme/GlobalStyle';
import Header from '../components/Header/Header';
import '../theme/reset.css';
import '../theme/bootstrap-grid.css';

import Footer from '../components/Footer/Footer';

const MainTemplate = ({ children }) => {
  return (
    <>
      <Header />
      <GlobalStyle />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
      <Footer />
    </>
  );
};

export default MainTemplate;
