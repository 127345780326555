import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { theme } from '../../theme/mainTheme';
import { StyledIconLink } from '../MainSection/MainSection';
import { StyledLogoLink } from '../Header/Header';

import piasek from '../../assets/img/piasek.png';
import Form from '../Form/Form';

const StyledFooter = styled.footer`
  background-color: black;
  padding-bottom: 17rem;
  background-size: cover;
  background-image: url(${piasek});
  background-repeat: no-repeat;
  background-position: center bottom;

  .top-row {
    .col-xl-3 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .bottom-row {
    a,
    p {
      text-transform: uppercase;
      color: ${theme.gray};
      font-family: 'Lexend Exa', sans-serif;
      font-size: 1.5rem;
    }

    .col {
      display: flex;
      align-items: center;
    }
    .col-xl-6 {
      justify-content: flex-end;
    }
  }

  @media (max-width: 1200px) {
    padding-bottom: 10rem;
    .top-row {
      padding-bottom: 0;
      .col {
        padding-bottom: 1.5rem;
      }
      .col-xl-3 {
        justify-content: center;
      }
    }
    .bottom-row {
      padding-top: 0;
      .col {
        padding-bottom: 1.5rem;
        p {
          text-align: center;
        }
      }
      .col-xl-6 {
        justify-content: center;
      }
      > div:nth-of-type(2) {
        justify-content: center;
      }
    }
  }
  @media (max-width: 992px) {
    .bottom-row {
      > div:nth-of-type(1) {
        justify-content: center;
      }
    }
  }
`;

const StyledFooterNav = styled.nav`
  ul {
    display: flex;
    align-items: center;
    li {
      font-family: 'Lexend Exa', sans-serif;
      font-size: 1.6rem;
      color: white;
      text-transform: uppercase;
      transition: all 250ms ease;
      padding: 2.4rem;
      a {
        color: inherit;
        font-family: inherit;
        &.active {
          color: ${theme.green};
        }
      }
      &:hover {
        color: ${theme.green};
      }
      :first-of-type {
        padding-left: 0;
      }
      :last-of-type {
        background: none;
        border: none;

        padding-right: 0;
        a {
          padding: 0;
          display: block;
        }

        &:hover {
          background: none;
          color: ${theme.green};
        }
      }
    }
  }

  @media (max-width: 992px) {
    ul {
      flex-wrap: wrap;
      justify-content: center;
      li {
        padding: 1rem;
      }
    }
  }
`;

const Footer = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === '/contact' ? '' : <Form />}
      <StyledFooter>
        <div className="container">
          <div className="row top-row">
            <div className="col col-12 col-xl-9 col-md-12">
              <StyledFooterNav>
                <ul>
                  <li>
                    <Link to="/services">Usługi</Link>
                  </li>
                  <li>
                    <Link to="/about">O nas</Link>
                  </li>
                  <li>
                    <Link to="/case-studies">Case studies</Link>
                  </li>
                  <li>
                    <Link to="/logbook">Dziennik pokładowy</Link>
                  </li>
                  <li>
                    <Link to="/contact">Kontakt</Link>
                  </li>
                </ul>
              </StyledFooterNav>
            </div>
            <div className="col col-12 col-xl-3 col-md-12">
              <div className="social-wrapper">
                <StyledIconLink
                  target="_blank"
                  href="https://www.linkedin.com/company/fastsite/about/"
                  linkedin
                />
                <StyledIconLink
                  target="_blank"
                  href="https://www.facebook.com/FASTSITEPL"
                  facebook
                />
              </div>
            </div>
          </div>

          <div className="row bottom-row">
            <div className="col col-12 col-xl-3 col-lg-6 col-md-12">
              <div className="logo-wrapper">
                <StyledLogoLink to="/" />
              </div>
            </div>
            <div className="col col-12 col-xl-3 col-lg-6 col-md-12">
              <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
            </div>
            <div className="col col-12 col-xl-6 col-lg-12">
              <p>Copyright @ 2021 FASTSITE.PL | ALL RIGHTS RESERVED</p>
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  );
};

export default Footer;
