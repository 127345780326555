import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../theme/mainTheme';
import StyledLink from '../Link/Link';

import liIcon from '../../assets/svg/linkedin.svg';
import fbIcon from '../../assets/svg/messenger.svg';

const StyledMainSection = styled.section`
  height: unset;
  min-height: calc(100vh - 10rem);
  position: relative;
  /* padding-bottom: 23rem; */
  display: flex;
  align-items: center;
  padding: 4rem 0;
  @media (max-width: 576px) {
    padding: 2rem 0;
  }
  .container {
    z-index: 1;
  }

  .header-wrapper {
    h1 {
      color: white;
      span {
        color: ${theme.green};
      }
    }
  }
  .text-wrapper {
    margin: 2rem 0 8rem;
    p {
      font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
      max-width: 57rem;
      color: ${theme.gray};
    }
  }
  .buttons-wrapper {
    margin-bottom: 3.2rem;
    a {
      display: inline-block;
    }
    a:first-of-type {
      margin: 0 1.6rem 1.6rem 0;
    }
  }
`;

export const StyledIconLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6rem;
  transition: all 250ms ease;
  &:after {
    content: '';
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    ${({ linkedin }) =>
      linkedin &&
      css`
        background-image: url(${liIcon});
      `}

    ${({ facebook }) =>
      facebook &&
      css`
        background-image: url(${fbIcon});
      `}
  }
`;

const StyledDecorators = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Decorator = styled.div`
  width: 2px;
  height: 2px;
  background: white;
  filter: blur(2px);
  position: absolute;
  top: ${({ top }) => `${top}%`};
  left: ${({ left }) => `${left}%`};
  animation: blink 1s linear infinite alternate;
  animation-duration: ${({ duration }) => `${duration}ms`};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    min-width: 4px;
    background: white;
    filter: blur(4px);
    display: ${({ shadow }) => (shadow ? 'block' : 'none')};
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const MainSection = ({ title, claim, noButtons, caseStudiesPage }) => (
  <StyledMainSection>
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-8">
          <div className="header-wrapper">
            <h1>{title}</h1>
          </div>
          <div className="text-wrapper">
            <p>{claim}</p>
          </div>
          {!noButtons && (
            <div className="buttons-wrapper">
              {caseStudiesPage ? (
                <StyledLink green to="/services">
                  Nasze usługi
                </StyledLink>
              ) : (
                <StyledLink to="/case-studies" green>
                  Portfolio
                </StyledLink>
              )}

              <StyledLink borderTransparent to="/contact">
                Kontakt
              </StyledLink>
            </div>
          )}
          <div className="social-wrapper">
            <StyledIconLink
              target="_blank"
              href="https://www.linkedin.com/company/fastsite/about/"
              linkedin
            />
            <StyledIconLink
              target="_blank"
              href="https://www.facebook.com/FASTSITEPL"
              facebook
            />
          </div>
        </div>
        <div className="col col-12 col-lg-4"></div>
      </div>
    </div>
    <StyledDecorators>
      {[...Array(21)].map((i, e) => (
        <Decorator
          top={Math.random() * 100}
          left={Math.random() * 100}
          duration={Math.random() * 1500 + 800}
          shadow={Math.random() < 0.5}
          key={e}
        />
      ))}
    </StyledDecorators>
  </StyledMainSection>
);

export default MainSection;
