import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';
import { Link } from 'react-router-dom';

const StyledArticleCard = styled.div`
  /* max-width: 415px; */
  img {
    object-fit: contain;
    width: 100%;
    margin-bottom: 1.6rem;
    height: 30rem;
    object-fit: cover;
    object-position: center;
  }
  p.date {
    color: ${theme.gray};
    font-size: 2.2rem;
  }
  a.title {
    color: white;
    margin: 0.8rem 0 1.6rem;
    font-size: 2.5rem;
    transition: all 250ms ease;
    display: inline-block;
    &:hover {
      color: ${theme.green};
    }
  }
  p.excerpt {
    color: ${theme.gray};
    /* text-align: justify; */
    font-size: 2rem;
  }
  @media (max-width: 576px) {
    img {
      height: 23rem;
    }
    p.date {
      font-size: 1.8rem;
    }
    a.title {
      font-size: 2.2rem;
    }
    p.excerpt {
      font-size: 1.6rem;
    }
  }
`;

const ArticleCard = ({ image, date, title, excerpt, id, link }) => {
  return (
    <StyledArticleCard key={id}>
      <Link to={link}>
        <img src={image} alt={title} />
      </Link>
      <p className="date">{date.slice(0, 10)}</p>
      <Link
        className="title"
        to={link}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p className="excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
    </StyledArticleCard>
  );
};

export default ArticleCard;
