import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';

const StyledMemberCard = styled.div`
  img {
    object-fit: contain;
    width: 100%;
    margin-bottom: 1rem;
  }
  p:first-of-type {
    color: white;
    font-size: 3.2rem;
    font-size: calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320)));
  }
  p:nth-of-type(2) {
    color: ${theme.green};
    margin: 1.6rem 0;
    font-size: 2rem;
  }
  p.desc {
    color: ${theme.gray};
    text-align: justify;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  }
`;

const MemberCard = ({ image, name, position, description, id }) => (
  <StyledMemberCard key={id}>
    <img src={image} alt={name} />
    <p>{name}</p>
    <p>{position}</p>
    <p className="desc">{description}</p>
  </StyledMemberCard>
);

export default MemberCard;
