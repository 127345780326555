async function getPosts(param) {
  const url = 'https://fastsite.pl/wp-json/wp/v2/posts' + param;

  const response = await fetch(url);

  //  console.log(process.env.REACT_APP_WP_ADMIN_URL + "/wp-json/wp/v2/posts");

  console.log(url);
  return await response.json();
}

export default getPosts;
