import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import GrayBox from '../components/GrayBox/GrayBox';
import SectionHeading from '../components/SectionHeading/SectionHeading';
import GridTemplate from '../templates/GridTemplate';
import ArticleCard from '../components/ArticleCard/ArticleCard';
import MainSection from '../components/MainSection/MainSection';

import getPosts from '../api/getPosts';

const StyledGrayBox = styled(GrayBox)`
  margin-bottom: 8rem;
`;

const Logbook = () => {
  const [posts, setPosts] = useState([]);
  const [stickyPosts, setStickyPosts] = useState([]);

  useEffect(() => {
    getPosts('?sticky=false&_embed').then((result) => setPosts(result));
    getPosts('?sticky=true&_embed').then((result) => setStickyPosts(result));
  }, []);

  return (
    <>
      <MainSection
        noButtons
        title="Dziennik pokładowy"
        claim="Zobacz co odkryliśmy w przestrzeni Internetu"
      />

      <section>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <StyledGrayBox>
                <SectionHeading
                  title="Popularne wpisy"
                  claim="Tu zacznij przygodę z naszym dziennikiem"
                />
                <GridTemplate columns="col4">
                  {stickyPosts.map(
                    (post) =>
                      (post = (
                        <ArticleCard
                          id={post.id}
                          date={post.date}
                          title={[post.title.rendered]}
                          excerpt={[post.excerpt.rendered]}
                          image={post._embedded['wp:featuredmedia'][0].link}
                          link={`/${[post.slug]}`}
                        />
                      ))
                  )}
                </GridTemplate>
              </StyledGrayBox>
              <GridTemplate columns="col2">
                {posts.map(
                  (post) =>
                    (post = (
                      <ArticleCard
                        id={post.id}
                        date={post.date}
                        title={[post.title.rendered]}
                        excerpt={[post.excerpt.rendered]}
                        image={post._embedded['wp:featuredmedia'][0].link}
                        link={`/${[post.slug]}`}
                      />
                    ))
                )}
              </GridTemplate>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Logbook;
