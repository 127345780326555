import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';
import StyledLink from '../Link/Link';

const StyledBrief = styled.section`
  .wrapper {
    overflow: hidden;
    padding: 5rem 7rem;
    background: ${theme.gray3};
    border-width: 2px;
    border-style: solid;
    -o-border-image: linear-gradient(to top, #00d695, transparent);
    border-image: linear-gradient(to top, #00d695, transparent);
    border-image-slice: 2;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      color: white;
      margin-bottom: 3.2rem;
    }
    &:after {
      content: '';
      background: url('https://fastsite.pl/wp-content/themes/fastsite-theme/assets/img/planeta-0.png')
        no-repeat center center;
      background-size: contain;
      display: block;
      position: absolute;
      right: -30%;
      bottom: -50%;
      width: 80%;
      height: 80vh;
      transform: rotate(180deg);
      z-index: -1;
    }
    @media (max-width: 576px) {
      padding: 3rem 5rem;
      h3 {
        text-align: center;
      }
      align-items: center;
    }
  }
`;

const Brief = () => (
  <StyledBrief>
    <div className="container">
      <div className="row">
        <div className="col col-12">
          <div className="wrapper">
            <h3>
              Ruszasz w podróż?
              <br />Z naszej karty pokładowej dowiesz się co i jak.
            </h3>
            <StyledLink
              iconGreen
              download
              to={{
                pathname:
                  'https://fastsite.pl/wp-content/uploads/2021/10/FASTSITE_BRIEF_WYSTARTUJ_Z_NAMI-1.docx',
              }}
            >
              {' '}
              Pobierz brief
            </StyledLink>
          </div>
        </div>
      </div>
    </div>
  </StyledBrief>
);

export default Brief;
