import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';

const StyledForm = styled.section`
  .container {
    background-color: ${theme.green};
    padding: 5rem 7rem;
    background-size: contain;
    .text-wrapper {
      h2 {
        color: white;
      }
      a {
        color: black;
        font-size: 3rem;
        font-family: 'Lexend Exa', sans-serif;
        margin-top: 3rem;
        display: inline-block;
      }
    }
  }
`;

const Form = ({ image, date, title, excerpt }) => (
  <StyledForm>
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-6 col-md-12">
          <div className="text-wrapper">
            <h2>Wystartuj z nami!</h2>
            <a href="mailto:hello@fastsite.pl">hello@fastsite.pl</a>
          </div>
        </div>
        <div className="col col-12 col-lg-6 col-md-12">
          <p>Formularz</p>
        </div>
      </div>
    </div>
  </StyledForm>
);

export default Form;
