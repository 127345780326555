import { createGlobalStyle } from 'styled-components';
import { theme } from './mainTheme';

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    font-size: 62.5%; 
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
  
  body {
    overflow-x: hidden;
    background: ${theme.black};
    font-size: 1.6rem;
  }


  section{
    padding: 8rem 0;
  }

  .row{
padding: 1.6rem 0
  }

    /// FONTS?
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    input,
    label,
    textarea, button {
    line-height: 1.25;
    }

    p {
    line-height: 1.5;
    }
    
    p,a,li,span,div, button {
    font-family: "Lexend Deca", sans-serif;
    }

    h1,h2,h3,h4,h5,h6, input,
label,
textarea {
  font-family: "Lexend Exa", sans-serif;
}

h1 span{
  font-family: "Lexend Exa", sans-serif;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

input,
label,
textarea {
  font-size: 15px;
}


h1 {
    font-size: calc(
    36px + (70 - 36) * ((100vw - 320px) / (1920 - 320))
  );
}

h2 {
    font-size: calc(
    28px + (80 - 28) * ((100vw - 320px) / (1920 - 320))
  );
}

h3 {
    font-size: calc(
    24px + (35 - 24) * ((100vw - 320px) / (1920 - 320))
  );
}


//uzupelnic
h4 {
    font-size: calc(
    24px + (35 - 24) * ((100vw - 320px) / (1920 - 320))
  );
    }

    h5 {
    font-size: calc(
    24px + (35 - 24) * ((100vw - 320px) / (1920 - 320))
  );

  h6 {
    font-size: calc(
    24px + (35 - 24) * ((100vw - 320px) / (1920 - 320))
  );
    }
    }


  
`;

export default GlobalStyle;
