import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';

const GrayBox = styled.div`
  background: ${theme.gray3};
  padding: 5rem;

  @media (max-width: 1200px) {
    padding: 2.5rem;
  }
  @media (max-width: 576px) {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
`;

export default GrayBox;
