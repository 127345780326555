import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import About from './About';
import CaseStudies from './CaseStudies';
import Logbook from './Logbook';
import Contact from './Contact';
import Single from './Single';

import MainTemplate from '../templates/MainTemplate';

const Root = ({ children }) => (
  <BrowserRouter>
    <MainTemplate>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/about" component={About} />
        <Route exact path="/case-studies" component={CaseStudies} />
        <Route exact path="/logbook" component={Logbook} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/:slug" component={Single} />
      </Switch>
    </MainTemplate>
  </BrowserRouter>
);

export default Root;
