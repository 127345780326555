import Slider from 'react-slick';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';
import arrow from '../../assets/svg/white-arrow.svg';

const StyledSlider = styled.div`
  .slick-slider {
    display: flex;
    flex-direction: column;
  }

  .slick-dots {
    text-align: center;
    position: relative;
    order: 1;
    padding: 1rem;
    padding-top: 5rem;
    bottom: 0;

    li {
      text-align: center;
      margin: 1rem;
      width: unset;
      height: 5rem;
      border-width: 2px;
      border-style: solid;
      -o-border-image: linear-gradient(to top, #00d695, transparent);
      border-image: linear-gradient(to top, #00d695, transparent);
      border-image-slice: 2;
      transition: all 250ms ease;
      background: transparent;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        display: inline-flex;
        width: 100%;
        height: 100%;
        padding: 0 2rem;
        justify-content: center;
        align-items: center;
        color: white;
      }
      button {
        &:before {
          display: none;
        }
      }
      &:hover {
        background: ${theme.green};
      }
    }
  }

  .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    -o-border-image: linear-gradient(to top, #00d695, transparent);
    border-image: linear-gradient(to top, #00d695, transparent);
    border-image-slice: 2;
    background-color: transparent;
    width: 5rem;
    height: 5rem;
    transition: all 250ms ease;
    z-index: 10;
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;

    &:before {
      display: none;
    }
    &:after {
      content: '';
      width: 2.5rem;
      height: 2.5rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(${arrow});
      background-color: unset;
    }

    &.slick-next {
      bottom: 20%;
      right: 10%;
      &:after {
        transform: rotate(180deg);
      }
    }
    &.slick-prev {
      bottom: 20%;
      right: 20%;
    }

    &:hover {
      background: ${theme.green};
    }
  }
  @media (max-width: 992px) {
    display: none;
  }
`;

const StyledSingleSlide = styled.div`
  height: 55rem;
  display: flex;
  .text-wrapper {
    width: 40%;
    p {
      color: white;
      font-size: 1.6rem;
    }
    p.title {
      color: ${theme.green};
      /* font-size: 4.5rem; */
      font-size: calc(28px + (45 - 28) * ((100vw - 320px) / (1920 - 320)));
      text-transform: uppercase;
      margin-bottom: 3.2rem;
    }
    ul {
      display: flex;
      flex-direction: column;
      margin-top: 1.6rem;
      li {
        padding: 0.8rem 0;
        color: white;
        font-size: 2rem;
        display: inline-flex;
        align-items: center;
        position: relative;
        &:before {
          content: '';
          width: 12px;
          height: 2px;
          background-color: #00d695;
          margin-right: 12px;
        }
      }
    }
  }
  .image-wrapper {
    width: 60%;
  }

  @media (max-width: 992px) {
    height: unset;
    margin-bottom: 3.2rem;
    .text-wrapper {
      width: 100%;
      p.title {
        margin-bottom: 1.6rem;
      }
      ul li {
        font-size: 1.6rem;
      }
    }
    .image-wrapper {
      display: none;
    }
  }
`;

const StyledMobileServices = styled.div`
  @media (min-width: 993px) {
    display: none;
  }
`;

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    nextArrow: (
      <div>
        <p>Następna usługa</p>
      </div>
    ),
    prevArrow: <div></div>,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      const titles = [
        'Consulting',
        'Projektowanie UX/UI',
        'Kreacja',
        'Strony internetowe',
        'Analiza danych',
        'Konserwacja',
      ];
      return <p>{`${titles[i]}`}</p>;
    },
  };

  return (
    <>
      <StyledSlider>
        <Slider {...settings}>
          <StyledSingleSlide>
            <div className="text-wrapper">
              <p className="title">Consulting</p>
              <p>
                Nasze usługi consultingowe opieramy na doświadczeniu i wiedzy
                zdobytej na wielu projektach. Specjalizujemy się w szeroko
                rozumianym marketingu, IT i finansach. Naszym celem w każdym
                projekcie doradczym jest zaproponowanie takiego rozwiązania,
                które skutecznie rozwiąże przedstawiony problem, a jednocześnie
                będzie realne do wdrożenia.
              </p>
              <ul>
                <li> Strategia marki</li>
                <li>Strategia komunikacji marki</li>
                <li>Design sprint</li>
                <li>Finanse & Controlling finansowy</li>
                <li>Business Intelligence & IT</li>
              </ul>
            </div>
            <div className="image-wrapper">
              <img src="" />
            </div>
          </StyledSingleSlide>

          <StyledSingleSlide>
            <div className="text-wrapper">
              <p className="title">PROJEKTOWANIE UX/UI</p>
              <p>
                Projektowanie UX / UI to złożony proces planowania całego
                zestawu doświadczeń, jakie użytkownik przeżywa podczas
                interakcji z aplikacją lub stroną internetową. Prace zaczynamy
                od Warsztatu UX, na którym tworzymy makiety funkcjonalne, a
                następnie projektujemy dobrze wyglądające i efektywne
                interfejsy. Później pozostaje już tylko testowanie wyników w
                celu dalszego rozwoju.
              </p>
              <ul>
                <li>Warsztaty UX</li>
                <li>Makietowanie</li>
                <li>Projektowanie UI</li>
                <li>Testy użyteczności</li>
              </ul>
            </div>
            <div className="image-wrapper">
              <img src="" />
            </div>
          </StyledSingleSlide>

          <StyledSingleSlide>
            <div className="text-wrapper">
              <p className="title">KREACJA</p>
              <p>
                Kreacja to najszersza kategoria naszych usług. Jesteśmy w stanie
                stworzyć wszystko, co wymaga połączenia pomysłowości i artyzmu z
                różnorodnymi umiejętnościami twardymi, takimi jak grafika
                wektorowa lub 3D czy obsługa menedżera reklam. W naszym
                portfolio można znaleźć logotypy, pełne identyfikacje wizualne,
                broszury, wizytówki i wiele więcej. To co wyróżnia nasze usługi
                kreatywne od konkurencji to pełna zgodność ze strategią marki.
              </p>
              <ul>
                <li>Identyfikacje wizualne (Branding)</li>
                <li>Materiały do druku (DTP)</li>
                <li>Copywriting</li>
                <li>Animacje & Video</li>
                <li>Social media</li>
                <li>Modelowanie 3D</li>
              </ul>
            </div>
            <div className="image-wrapper">
              <img src="" />
            </div>
          </StyledSingleSlide>

          <StyledSingleSlide>
            <div className="text-wrapper">
              <p className="title">STRONY INTERNETOWE</p>
              <p>
                Naszą podróż biznesową rozpoczęliśmy od tworzenia stron
                internetowych. Nic więc dziwnego, że traktujemy je ze
                szczególnym sentymentem. Nasze strony internetowe są zawsze
                szyte na miarę i wykonywane z wykorzystaniem technologii
                najlepiej dopasowanych do danego celu.
              </p>
              <ul>
                <li>Strony internetowe</li>
                <li>E-commerce</li>
                <li>Aplikacje webowe</li>
                <li>Wtyczki & Integracje</li>
              </ul>
            </div>
            <div className="image-wrapper">
              <img src="" />
            </div>
          </StyledSingleSlide>

          <StyledSingleSlide>
            <div className="text-wrapper">
              <p className="title">ANALIZA DANYCH & AUTOMATYZACJA PROCESÓW</p>
              <p>
                Podstawą podejmowania decyzji biznesowych powinna być
                odpowiednia analiza rozwoju firmy i regularne monitorowanie
                rynku. Niestety, te często powtarzalne i żmudne czynności są
                powszechnie zaniedbywane. Nasz zespół analityczny z
                przyjemnością podejmie się tych obowiązków lub pomoże Ci je
                zautomatyzować.
              </p>
              <ul>
                <li> Business Intelligence & Modelowanie statystyczne</li>
                <li>Mapowanie procesów biznesowych</li>
                <li>Arkusze kalkulacyjne & Dashboardy</li>
                <li>Makra, Query, Skrypty</li>
              </ul>
            </div>
            <div className="image-wrapper">
              <img src="" />
            </div>
          </StyledSingleSlide>

          <StyledSingleSlide>
            <div className="text-wrapper">
              <p className="title">KONSERWACJA STRON</p>
              <p>
                Celem prac konserwacyjnych (maintenance) jest zapewnienie
                stronom internetowym bezpieczeństwa i sprawności działania. W
                końcu zawsze łatwiej zapobiegać niż leczyć. Jest to główny
                powód, dla którego rekomendujemy usługi z tego obszaru każdemu
                właścicielowi strony internetowej.
              </p>
              <ul>
                <li> Kopie zapasowe</li>
                <li>Aktualizacje</li>
                <li>Zarządzanie treściami</li>
                <li>Administracja stron</li>
              </ul>
            </div>
            <div className="image-wrapper">
              <img src="" />
            </div>
          </StyledSingleSlide>
        </Slider>
      </StyledSlider>

      <StyledMobileServices>
        <StyledSingleSlide>
          <div className="text-wrapper">
            <p className="title">Consulting</p>
            <p>
              Nasze usługi consultingowe opieramy na doświadczeniu i wiedzy
              zdobytej na wielu projektach. Specjalizujemy się w szeroko
              rozumianym marketingu, IT i finansach. Naszym celem w każdym
              projekcie doradczym jest zaproponowanie takiego rozwiązania, które
              skutecznie rozwiąże przedstawiony problem, a jednocześnie będzie
              realne do wdrożenia.
            </p>
            <ul>
              <li> Strategia marki</li>
              <li>Strategia komunikacji marki</li>
              <li>Design sprint</li>
              <li>Finanse & Controlling finansowy</li>
              <li>Business Intelligence & IT</li>
            </ul>
          </div>
          <div className="image-wrapper">
            <img src="" />
          </div>
        </StyledSingleSlide>

        <StyledSingleSlide>
          <div className="text-wrapper">
            <p className="title">PROJEKTOWANIE UX/UI</p>
            <p>
              Projektowanie UX / UI to złożony proces planowania całego zestawu
              doświadczeń, jakie użytkownik przeżywa podczas interakcji z
              aplikacją lub stroną internetową. Prace zaczynamy od Warsztatu UX,
              na którym tworzymy makiety funkcjonalne, a następnie projektujemy
              dobrze wyglądające i efektywne interfejsy. Później pozostaje już
              tylko testowanie wyników w celu dalszego rozwoju.
            </p>
            <ul>
              <li>Warsztaty UX</li>
              <li>Makietowanie</li>
              <li>Projektowanie UI</li>
              <li>Testy użyteczności</li>
            </ul>
          </div>
          <div className="image-wrapper">
            <img src="" />
          </div>
        </StyledSingleSlide>

        <StyledSingleSlide>
          <div className="text-wrapper">
            <p className="title">KREACJA</p>
            <p>
              Kreacja to najszersza kategoria naszych usług. Jesteśmy w stanie
              stworzyć wszystko, co wymaga połączenia pomysłowości i artyzmu z
              różnorodnymi umiejętnościami twardymi, takimi jak grafika
              wektorowa lub 3D czy obsługa menedżera reklam. W naszym portfolio
              można znaleźć logotypy, pełne identyfikacje wizualne, broszury,
              wizytówki i wiele więcej. To co wyróżnia nasze usługi kreatywne od
              konkurencji to pełna zgodność ze strategią marki.
            </p>
            <ul>
              <li>Identyfikacje wizualne (Branding)</li>
              <li>Materiały do druku (DTP)</li>
              <li>Copywriting</li>
              <li>Animacje & Video</li>
              <li>Social media</li>
              <li>Modelowanie 3D</li>
            </ul>
          </div>
          <div className="image-wrapper">
            <img src="" />
          </div>
        </StyledSingleSlide>

        <StyledSingleSlide>
          <div className="text-wrapper">
            <p className="title">STRONY INTERNETOWE</p>
            <p>
              Naszą podróż biznesową rozpoczęliśmy od tworzenia stron
              internetowych. Nic więc dziwnego, że traktujemy je ze szczególnym
              sentymentem. Nasze strony internetowe są zawsze szyte na miarę i
              wykonywane z wykorzystaniem technologii najlepiej dopasowanych do
              danego celu.
            </p>
            <ul>
              <li>Strony internetowe</li>
              <li>E-commerce</li>
              <li>Aplikacje webowe</li>
              <li>Wtyczki & Integracje</li>
            </ul>
          </div>
          <div className="image-wrapper">
            <img src="" />
          </div>
        </StyledSingleSlide>

        <StyledSingleSlide>
          <div className="text-wrapper">
            <p className="title">ANALIZA DANYCH & AUTOMATYZACJA PROCESÓW</p>
            <p>
              Podstawą podejmowania decyzji biznesowych powinna być odpowiednia
              analiza rozwoju firmy i regularne monitorowanie rynku. Niestety,
              te często powtarzalne i żmudne czynności są powszechnie
              zaniedbywane. Nasz zespół analityczny z przyjemnością podejmie się
              tych obowiązków lub pomoże Ci je zautomatyzować.
            </p>
            <ul>
              <li> Business Intelligence & Modelowanie statystyczne</li>
              <li>Mapowanie procesów biznesowych</li>
              <li>Arkusze kalkulacyjne & Dashboardy</li>
              <li>Makra, Query, Skrypty</li>
            </ul>
          </div>
          <div className="image-wrapper">
            <img src="" />
          </div>
        </StyledSingleSlide>

        <StyledSingleSlide>
          <div className="text-wrapper">
            <p className="title">KONSERWACJA STRON</p>
            <p>
              Celem prac konserwacyjnych (maintenance) jest zapewnienie stronom
              internetowym bezpieczeństwa i sprawności działania. W końcu zawsze
              łatwiej zapobiegać niż leczyć. Jest to główny powód, dla którego
              rekomendujemy usługi z tego obszaru każdemu właścicielowi strony
              internetowej.
            </p>
            <ul>
              <li> Kopie zapasowe</li>
              <li>Aktualizacje</li>
              <li>Zarządzanie treściami</li>
              <li>Administracja stron</li>
            </ul>
          </div>
          <div className="image-wrapper">
            <img src="" />
          </div>
        </StyledSingleSlide>
      </StyledMobileServices>
    </>
  );
};

export default SliderComponent;
