import styled, { css } from 'styled-components';

const StyledGridTemplate = styled.div`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  /* margin-top: 3.2rem; */
  margin-left: -1.6rem;
  margin-right: -1.6rem;

  div.wrapper-inner {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    padding-bottom: 3.2rem;
  }

  /* ${({ col2 }) =>
    col2 &&
    css`
      div.wrapper-inner {
        width: 50%;
      }
    `} */

  &.col2 {
    div.wrapper-inner {
      width: 50%;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }
  &.col3 {
    div.wrapper-inner {
      width: 33.33%;
      @media (max-width: 1200px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  &.col4 {
    div.wrapper-inner {
      width: 25%;
      @media (max-width: 1200px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

const GridTemplate = ({ children, columns, id }) => (
  <StyledGridTemplate className={`${columns}`}>
    {children.map(
      (child, i) =>
        child && (
          <div className="wrapper-inner" key={i}>
            {child}
          </div>
        )
    )}
  </StyledGridTemplate>
);

export default GridTemplate;
