import styled, { css } from 'styled-components';
import { theme } from '../../theme/mainTheme';
import downloadIcon from '../../assets/svg/download.svg';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  padding: 1.6rem 2.4rem;
  font-size: 1.8rem;
  @media (max-width: 576px) {
    font-size: 1.4rem;
  }
  text-transform: uppercase;
  display: inline-block;
  transition: all 250ms ease;
  border: none;
  border-radius: 0;
  background: none;
  border: 1px solid transparent;
  color: white;
  height: 55px;
  &:hover {
    cursor: pointer;
  }

  ${({ green }) =>
    green &&
    css`
      background: ${theme.green};
      &:hover {
        background: transparent;
        border-color: ${theme.green};
      }
    `}

  ${({ borderGreen }) =>
    borderGreen &&
    css`
      border-color: ${theme.green};
      &:hover {
        background: ${theme.green};
      }
    `}

    ${({ borderWhite }) =>
    borderWhite &&
    css`
      border-color: white;
      &:hover {
        background: white;
        color: ${theme.green};
      }
    `}

    ${({ borderTransparent }) =>
    borderTransparent &&
    css`
      &:hover {
        border-color: white;
      }
    `}

    ${({ iconGreen }) =>
    iconGreen &&
    css`
      background: ${theme.green};
      display: inline-flex;
      align-items: center;
      &:after {
        display: inline-block;
        margin-left: 10px;
        content: url(${downloadIcon});
      }
      &:hover {
        background: transparent;
        border-color: ${theme.green};
      }
    `}
`;

export default StyledLink;
