import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../theme/mainTheme';

const StyledSectionHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
  h2,
  a {
    color: white;
  }
  p {
    color: ${theme.gray};
    /* font-size: 2rem; */
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
  }
  h2 {
    margin-bottom: 1.6rem;
  }
  a {
    /* font-size: 3rem; */
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 2rem;
    &:hover {
      color: ${theme.green};
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    a {
      padding-left: 0;
      padding-top: 2rem;
    }
  }
`;

const SectionHeading = ({ title, claim, link }) => (
  <StyledSectionHeading>
    <div>
      <h2>{title}</h2>
      <p>{claim}</p>
    </div>
    {link && <Link to={`/${link}`}>Zobacz więcej</Link>}
  </StyledSectionHeading>
);

export default SectionHeading;
