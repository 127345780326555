import React, { useState, useEffect } from 'react';
import GrayBox from '../components/GrayBox/GrayBox';
import GridTemplate from '../templates/GridTemplate';
import SectionHeading from '../components/SectionHeading/SectionHeading';
import ArticleCard from '../components/ArticleCard/ArticleCard';
import CaseStudyCard from '../components/CaseStudyCard/CaseStudyCard';
import Brief from '../components/Brief/Brief';
import MainSection from '../components/MainSection/MainSection';
import getPosts from '../api/getPosts';
import getCPS from '../api/getCPS';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    getPosts('/?per_page=4&_embed').then((result) => setPosts(result));
    getCPS('fastsite_projects?per_page=4').then((result) =>
      setCaseStudies(result)
    );
  }, []);

  return (
    <>
      <MainSection
        title={['Tworzymy ', <span>produkty</span>, ', które realizują cele.']}
        claim="Dzięki naszej opartej o warsztaty metodologii prowadzenia projektów, tworzymy produkty na miarę Twojego biznesu."
      />

      <section style={{ paddingTop: '0' }}>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <GrayBox>
                <SectionHeading
                  title="Case studies"
                  claim="Zobacz z jakimi wyzwaniami nasza załoga mierzy się każdego dnia"
                  link="case-studies"
                />
                <GridTemplate columns="col2">
                  {caseStudies.map(
                    (caseStudy) =>
                      (caseStudy = caseStudy.acf.url_case_study && (
                        <CaseStudyCard
                          id={[caseStudy.id]}
                          company={[caseStudy.acf.podtytul]}
                          title={[caseStudy.acf.tytul]}
                          link={`/${[caseStudy.slug]}`}
                          linkToSite={[caseStudy.acf.url_realizacji]}
                          image={[caseStudy.acf.zdjecie_realizacji]}
                        />
                      ))
                  )}
                </GridTemplate>
              </GrayBox>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <SectionHeading
                title="Najnowsze wpisy"
                claim="Wszystkie odkrycia opisujemy w naszym dzienniku pokładowym"
                link="logbook"
              />
              <GridTemplate columns="col4">
                {posts.map(
                  (post) =>
                    (post = (
                      <ArticleCard
                        id={[post.id]}
                        date={post.date}
                        title={[post.title.rendered]}
                        excerpt={[post.excerpt.rendered]}
                        image={post._embedded['wp:featuredmedia'][0].link}
                        link={`/${[post.slug]}`}
                      />
                    ))
                )}
              </GridTemplate>
            </div>
          </div>
        </div>
      </section>

      <Brief />
    </>
  );
};

export default Home;
