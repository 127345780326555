import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../theme/mainTheme';
import astronaura from '../assets/svg/kim-jestesmy.svg';
import MemberCard from '../components/MemberCard/MemberCard';
import GrayBox from '../components/GrayBox/GrayBox';
import GridTemplate from '../templates/GridTemplate';
import MainSection from '../components/MainSection/MainSection';
import getAcf from '../api/getAcf';

const StyledGrayBox = styled(GrayBox)`
  h2 {
    color: white;
    margin-bottom: 6.4rem;
  }

  .info-wrapper {
    display: flex;
    margin-bottom: 3rem;
  }

  .text-wrapper {
    width: 60%;
    p {
      color: ${theme.gray};
      font-size: 2rem;
    }
  }
  .image-wrapper {
    width: 40%;
    display: flex;
    justify-content: center;
    padding: 0 1.6rem;
    img {
      width: 100%;
      object-fit: contain;
      height: auto;
    }
  }
  @media (max-width: 992px) {
    h2 {
      margin-bottom: 3.2rem;
    }
    .info-wrapper {
      flex-direction: column;
      .text-wrapper {
        width: 100%;
        p {
          text-align: justify;
          font-size: 1.6rem;
        }
        margin-bottom: 2.4rem;
      }
      .image-wrapper {
        width: 100%;
        height: 40vh;
      }
    }
  }
`;

const About = () => {
  const [members, setMembers] = useState([]);
  useEffect(() => {
    getAcf('zespol').then((result) => setMembers(result));
  }, []);

  return (
    <>
      <MainSection title="O nas" claim="Poznaj załogę FASTSITE!" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <StyledGrayBox>
                <div className="info-wrapper">
                  <div className="text-wrapper">
                    <h2>Kim jesteśmy</h2>
                    <p>
                      FASTSITE to zespół astronautów, marzących o podboju
                      internetowego kosmosu. Nasza załoga składa się z młodych i
                      ambitnych programistów, grafików i analityków, którzy
                      uwielbiają stawiać czoła nowym wyzwaniom. Przed podjęciem
                      jakiejkolwiek misji zawsze określamy strategię i cele -
                      bez nich sukces to co najwyżej kwestia szczęścia! Strona
                      jest naszym statkiem kosmicznym - rozgość się i zwiedzaj
                      do woli!
                    </p>
                  </div>
                  <div className="image-wrapper">
                    <img src={astronaura} alt="" />
                  </div>
                </div>
                <div className="team-wrapper">
                  <h2>Nasza załoga</h2>
                  <GridTemplate columns="col3">
                    {members.map(
                      (member) =>
                        (member = (
                          <MemberCard
                            key={[member.id]}
                            image={[member.acf.zdjecie]}
                            name={[member.acf.imie_i_nazwisko]}
                            position={[member.acf.stanowisko]}
                            description={[member.acf.opis]}
                          />
                        ))
                    )}
                  </GridTemplate>
                </div>
              </StyledGrayBox>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
