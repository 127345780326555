import React, { useState, useEffect } from 'react';
import GrayBox from '../components/GrayBox/GrayBox';
import SectionHeading from '../components/SectionHeading/SectionHeading';
import GridTemplate from '../templates/GridTemplate';
import article from '../assets/img/article.png';
import ArticleCard from '../components/ArticleCard/ArticleCard';
import CaseStudyCard from '../components/CaseStudyCard/CaseStudyCard';
import MainSection from '../components/MainSection/MainSection';
import getCPS from '../api/getCPS';

const CaseStudies = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getCPS('fastsite_projects?per_page=50').then((result) => {
      setCaseStudies(result);
      setLoader(false);
    });
  }, []);

  return (
    <>
      <MainSection
        caseStudiesPage
        title="Case studies"
        claim="Zobacz z jakimi wyzwaniami nasza załoga mierzy się każdego dnia"
      />

      <section>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <GrayBox>
                {loader ? (
                  <h1>ładowanie danych (rakieta/satelita)</h1>
                ) : (
                  <GridTemplate columns="col2">
                    {caseStudies.map(
                      (caseStudy) =>
                        (caseStudy = caseStudy.acf.url_case_study && (
                          <CaseStudyCard
                            id={[caseStudy.id]}
                            company={[caseStudy.acf.podtytul]}
                            title={[caseStudy.acf.tytul]}
                            link={`/${[caseStudy.slug]}`}
                            linkToSite={[caseStudy.acf.url_realizacji]}
                            image={[caseStudy.acf.zdjecie_realizacji]}
                          />
                        ))
                    )}
                  </GridTemplate>
                )}
              </GrayBox>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <GridTemplate columns="col3">
                {caseStudies.map(
                  (caseStudy) =>
                    (caseStudy = !caseStudy.acf.url_case_study && (
                      <CaseStudyCard
                        id={caseStudy.id}
                        company={[caseStudy.acf.podtytul]}
                        title={[caseStudy.acf.tytul]}
                        linkToSite={[caseStudy.acf.url_realizacji]}
                        image={[caseStudy.acf.zdjecie_realizacji]}
                      />
                    ))
                )}
              </GridTemplate>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudies;
