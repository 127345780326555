import React from 'react';
import styled from 'styled-components';

import GrayBox from '../components/GrayBox/GrayBox';
import MainSection from '../components/MainSection/MainSection';
import { theme } from '../theme/mainTheme';
import services from '../assets/img/how-we-do.png';
import SliderComponent from '../components/Slider/Slider';

const StyledGrayBox = styled(GrayBox)`
  .title-wrapper {
    margin-bottom: 3rem;
    h2 {
      color: white;
      margin-bottom: 1.6rem;
      text-align: center;
    }
    p {
      text-align: center;
      font-size: 2rem;
      color: ${theme.gray};
    }
  }
  .content-wrapper {
    display: flex;
    align-items: center;
    .image-wrapper {
      padding: 0 3.2rem;
      width: 60%;
      img {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
    }
    .text-wrapper {
      width: 40%;
      padding: 0 3.2rem;
    }
  }

  @media (max-width: 1200px) {
    .content-wrapper {
      flex-direction: column;
      .image-wrapper {
        width: 100%;
        height: 40vh;
        margin-bottom: 3.2rem;
        img {
          height: 100%;
        }
      }
      .text-wrapper {
        width: 100%;
      }
    }
  }
  @media (max-width: 576px) {
    .content-wrapper {
      .image-wrapper,
      .text-wrapper {
        padding: 0;
      }
    }
  }
`;

const StyledStepBox = styled.div`
  margin-bottom: 4.8rem;
  p:first-of-type {
    /* font-size: 3rem; */
    font-size: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    color: ${theme.green};
  }
  p:nth-of-type(2) {
    /* font-size: 4rem; */
    font-size: calc(26px + (40 - 26) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    color: white;
    margin: 0.8rem 0 3.2rem;
  }
  p:last-of-type {
    font-size: 1.6rem;
    text-align: justify;
    color: ${theme.gray};
  }
  @media (max-width: 1200px) {
    margin-bottom: 3.2rem;
    p:nth-of-type(2) {
      margin: 0.8rem 0 2.2rem;
    }
  }
`;

const Services = () => (
  <>
    <MainSection
      title="Usługi"
      claim="Wszystko czego potrzebujesz, aby wyruszyć w kosmos!"
    />
    <section>
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <StyledGrayBox>
              <div className="title-wrapper">
                <h2>Jak działamy</h2>
                <p>Powiedz nam CO, a my powiemy JAK</p>
              </div>
              <div className="content-wrapper">
                <div className="image-wrapper">
                  <img src={services} />
                </div>
                <div className="text-wrapper">
                  <StyledStepBox>
                    <p>Krok 1.</p>
                    <p>Analiza</p>
                    <p>
                      Aby ustalić cele naszej misji i zaznajomić naszą załogę z
                      otoczeniem Twojej firmy każdy projekt rozpoczynamy od
                      dedykowanego warsztatu. Podczas tego spotkania
                      identyfikujemy potrzeby użytkowników, analizujemy rynek i
                      szukamy potencjalnych zagrożeń. Wierzymy, że odpowiednia
                      strategia to klucz do zwycięstwa.
                    </p>
                  </StyledStepBox>
                  <StyledStepBox>
                    <p>Krok 2.</p>
                    <p>Projektowanie</p>
                    <p>
                      Drugim etapem naszego procesu jest wizualizacja koncepcji,
                      czyli stworzenie prototypu. Jest to etap, w którym łatwo
                      we własnej kreatywności zgubić właściwy kierunek. Dzięki
                      naszemu procesowi zawsze koncentrujemy się na
                      projektowaniu najlepszych doświadczeń użytkownika (UX) i
                      użyteczności rozwiązań (UI), a nasze prace są zgodne ze
                      strategią marki i celami projektu.
                    </p>
                  </StyledStepBox>
                  <StyledStepBox>
                    <p>Krok 3.</p>
                    <p>Wdrożenie</p>
                    <p>
                      Z naszego punktu widzenia wdrożenie to tylko ostatni
                      element znacznie bardziej złożonego procesu
                      analityczno-kreatywnego. Na tym etapie potrzebne są jednak
                      bardzo konkretne twarde umiejętności oraz uważność i
                      skrupulatność. Wtedy możliwe jest dostarczenie najwyższej
                      jakości. Jest to powód, dla którego wszystkie nasze
                      wdrożenia są kontrolowane przez zespoły zaangażowane w
                      poprzednie fazy procesu.
                    </p>
                  </StyledStepBox>
                </div>
              </div>
            </StyledGrayBox>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <SliderComponent />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Services;
