import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/svg/logo.svg';
import { theme } from '../../theme/mainTheme';
import Hamburger from 'hamburger-react';

const StyledHeaderWrapper = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
`;

export const StyledLogoLink = styled(Link)`
  background-image: url(${logo});
  width: 20rem;
  min-width: 20rem;
  height: 7.5rem;
  background-position: center;
  background-size: contain;
  display: block;
  background-repeat: no-repeat;
`;

export const StyledNav = styled.nav`
  ul {
    display: flex;
    align-items: center;
    li {
      font-family: 'Lexend Exa', sans-serif;
      font-size: 1.6rem;
      color: white;
      text-transform: uppercase;
      transition: all 250ms ease;
      padding: 2.4rem;

      a {
        color: inherit;
        font-family: inherit;
        &.active {
          color: ${theme.green};
        }
      }
      &:hover {
        color: ${theme.green};
      }
      :last-of-type {
        background: ${theme.green};
        border: 1px solid transparent;
        padding: 0;
        a {
          padding: 1.6rem 2.4rem;
          display: block;
          &.active {
            color: white;
          }
        }

        &:hover {
          background: transparent;
          border-color: ${theme.green};
          color: white;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    transition: all 350ms ease-in-out;
    background: black;
    width: 150%;
    width: ${({ isOpen }) => (isOpen ? '150%' : '0')};
    margin-left: -150%;
    margin-left: ${({ isOpen }) => (isOpen ? '0' : '-150%')};
    height: calc(100vh - 10rem);
    position: absolute;
    top: 10rem;
    left: -25%;

    ul {
      flex-direction: column;
      overflow: hidden;
    }
  }
`;

const StyledHamburger = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
`;

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  function handleClick() {
    setOpen(false);
  }

  return (
    <header>
      <div className="container">
        <StyledHeaderWrapper>
          <StyledLogoLink to="/" />
          <StyledHamburger>
            <Hamburger toggled={isOpen} toggle={setOpen} color="#00d695" />
          </StyledHamburger>
          <StyledNav isOpen={isOpen}>
            <ul>
              <li>
                <NavLink
                  to="/services"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  Usługi
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  O nas
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/case-studies"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  Case studies
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/logbook"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  Dziennik pokładowy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  activeClassName="active"
                  onClick={handleClick}
                >
                  Kontakt
                </NavLink>
              </li>
            </ul>
          </StyledNav>
        </StyledHeaderWrapper>
      </div>
    </header>
  );
};

export default Header;
