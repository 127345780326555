import React from 'react';
import styled from 'styled-components';
import StyledLink from '../Link/Link';

const StyledCaseStudyCard = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ link }) => (link ? '40rem' : '35rem')};
  position: relative;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  background-image: url(${({ image }) => image});

  &:before {
    content: '';
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), transparent);
    opacity: 0.5;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    transition: all 250ms ease;
  }
  .text-wrapper {
    padding: 2.4rem;
    position: absolute;
    bottom: 0;
    transition: all 250ms ease;
    p.title {
      font-size: ${({ link }) => (link ? '3.8rem' : '2.4rem')};
      color: white;
    }
    p.company {
      font-size: 2.4rem;
      opacity: 52%;
      color: white;
      margin-top: 1rem;
    }
  }
  .buttons-wrapper {
    width: 100%;
    padding: 2.4rem;
    padding-top: 0;
    transition: all 250ms ease;
    position: absolute;
    bottom: -18rem;
    a {
      margin-bottom: 1.6rem;
      &:first-of-type {
        margin-right: 1.6rem;
      }
    }
  }

  &:hover {
    &:before {
      transition: all 250ms ease;
      opacity: 1;
    }
    .text-wrapper {
      bottom: 9.5rem;
    }
    .buttons-wrapper {
      // transform: scaleY(1);
      bottom: -1.6rem;
    }
  }

  @media (max-width: 992px) {
    height: ${({ link }) => (link ? '38rem' : '32rem')};
    .text-wrapper {
      p.title {
        font-size: ${({ link }) => (link ? '2.8rem' : '2.4rem')};
      }
      p.company {
        font-size: 1.8rem;
      }
    }
  }
  @media (max-width: 576px) {
    .text-wrapper {
      padding: 1.6rem;
    }
    .buttons-wrapper {
      padding: 1.6rem;
      padding-top: 0;
    }
  }
  @media (max-width: 425px) {
    &:hover {
      .text-wrapper {
        bottom: ${({ link }) => (link ? '16rem' : '10rem')};
      }
    }
  }
`;

const CaseStudyCard = ({ title, company, image, link, linkToSite, id }) => (
  <StyledCaseStudyCard image={image} link={link} key={id}>
    <div className="text-wrapper">
      <p className="title">{title}</p>
      <p className="company">{company}</p>
    </div>
    <div className="buttons-wrapper">
      {link && (
        <StyledLink borderGreen to={link}>
          Case study
        </StyledLink>
      )}
      <StyledLink
        borderWhite
        target="_blank"
        rel="noreferrer"
        to={{ pathname: `${linkToSite}` }}
      >
        Realizacja
      </StyledLink>
    </div>
  </StyledCaseStudyCard>
);

export default CaseStudyCard;
